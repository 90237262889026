/**
 * Author : Elia Contini - elia.contini.page
 * Created: 21 Mar 2020
 *
 * Blog
 */

import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogIndexPage = ({ data, location }) => {
   const siteTitle = data.site.siteMetadata.title;
   const posts = data.allMarkdownRemark.edges;
   
   return (
      <Layout location={location} title={siteTitle}>
         <SEO location={location} title="Blog - Elia Contini" />
         <div className="blogFeed">
            Subscribe the{" "}
            <a
               rel="alternate"
               type="application/rss+xml"
               href="http://feeds.feedburner.com/EliaContiniBlog"
            >
               RSS feed
            </a>
         </div>
         <div className="blogPosts">
            {posts.map(({ node }) => {
               const title = node.frontmatter.title;

               return (
                  <article
                     className="blogPost blogPost--listed"
                     key={node.fields.slug}
                  >
                     <header className="blogPost-header">
                        <h1 className="blogPost-title">
                           <Link to={node.fields.slug}>{title}</Link>
                        </h1>
                        <small className="blogPost-date">
                           {node.frontmatter.date}
                        </small>
                     </header>
                     <section>
                        <p
                           className="blogPost-summary"
                           dangerouslySetInnerHTML={{
                              __html: node.frontmatter.description,
                           }}
                        />
                     </section>
                  </article>
               );
            })}
         </div>
      </Layout>
   );
};

export default BlogIndexPage;

export const pageQuery = graphql`
   query {
      site {
         siteMetadata {
            title
         }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
         edges {
            node {
               excerpt
               fields {
                  slug
               }
               frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                  description
               }
            }
         }
      }
   }
`;
